import { Layout, notification, Progress } from 'antd';
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { ActionTypes } from "../state";
import { useTracked, useTrackedState } from '../state';

import { Helmet } from "react-helmet";

// @ts-ignore
import styles from "./sitelayout.module.scss";

import { validateToken } from "../state/actions";
import LoadingProgress from "../components/General/LoadingProgress";

const { Content } = Layout;

function SiteLayout({ children, location }) {

    const [state, dispatch] = useTracked();
    const [loadingProgressPercent, setLoadingProgressPercent] = useState<number>(0);
    const [loadingStatus, setLoadingStatus] = useState<"normal" | "active" | "success" | "exception">("active");
    const [loadingStatusText, setLoadingStatusText] = useState<string>("Laster");

    /*useEffect(() => {
        const totalItems = state.loadingprogress.items.length;
        const itemsLoaded = state.loadingprogress.items.filter((item) => item.hasLoaded === true).length;
        setLoadingProgressPercent((itemsLoaded / totalItems) * 100);
        if (itemsLoaded >= totalItems && totalItems > 0) {
            setLoadingStatus("success");
            setLoadingStatusText("Ferdig");
        } else {
            setLoadingStatus("active");
            setLoadingStatusText("Laster");
        }
    }, [state.loadingprogress]);

    useEffect(() => {
        console.log("update...")
        const totalItems = state.loadingprogress.items.length;
        const itemsLoaded = state.loadingprogress.items.filter((item) => item.hasLoaded === true).length;
        if (itemsLoaded >= totalItems && totalItems > 0) {
            console.log("close...")
            notification.close("progressNotification");
        } else {
            console.log("open...")
            notification.open({
                key: "progressNotification",
                duration: 30,
                message: 'Laster inn data',
                description: <Progress percent={loadingProgressPercent} status={loadingStatus} />,
            });
        }
    }, [state.loadingprogress]);*/

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: "#16191D" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>kinetik</title>
                <link rel="canonical" href="https://kinetik.on.convivial.no" />
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;900&display=swap" rel="stylesheet" />
                <script async defer data-domain="kinetik.no" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>
            <Content style={{}}>
                {children}
            </Content>
        </Layout>
    );
}

export default SiteLayout;
