/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Customer,
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerGetResponse
 */
export interface CustomerGetResponse {
    /**
     * 
     * @type {Customer}
     * @memberof CustomerGetResponse
     */
    customer?: Customer | null;
}

export function CustomerGetResponseFromJSON(json: any): CustomerGetResponse {
    return CustomerGetResponseFromJSONTyped(json, false);
}

export function CustomerGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerGetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customer': !exists(json, 'customer') ? undefined : CustomerFromJSON(json['customer']),
    };
}

export function CustomerGetResponseToJSON(value?: CustomerGetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customer': CustomerToJSON(value.customer),
    };
}


