/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StateElapsed
 */
export interface StateElapsed {
    /**
     * 
     * @type {Date}
     * @memberof StateElapsed
     */
    timestamp?: Date;
    /**
     * 
     * @type {number}
     * @memberof StateElapsed
     */
    elapsed?: number;
}

export function StateElapsedFromJSON(json: any): StateElapsed {
    return StateElapsedFromJSONTyped(json, false);
}

export function StateElapsedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateElapsed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'elapsed': !exists(json, 'elapsed') ? undefined : json['elapsed'],
    };
}

export function StateElapsedToJSON(value?: StateElapsed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'elapsed': value.elapsed,
    };
}


