/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HydropowerPlant,
    HydropowerPlantFromJSON,
    HydropowerPlantFromJSONTyped,
    HydropowerPlantToJSON,
} from './';

/**
 * 
 * @export
 * @interface HydropowerPlantsPatchResponse
 */
export interface HydropowerPlantsPatchResponse {
    /**
     * 
     * @type {HydropowerPlant}
     * @memberof HydropowerPlantsPatchResponse
     */
    hydropowerPlant?: HydropowerPlant | null;
}

export function HydropowerPlantsPatchResponseFromJSON(json: any): HydropowerPlantsPatchResponse {
    return HydropowerPlantsPatchResponseFromJSONTyped(json, false);
}

export function HydropowerPlantsPatchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydropowerPlantsPatchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hydropowerPlant': !exists(json, 'hydropowerPlant') ? undefined : HydropowerPlantFromJSON(json['hydropowerPlant']),
    };
}

export function HydropowerPlantsPatchResponseToJSON(value?: HydropowerPlantsPatchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hydropowerPlant': HydropowerPlantToJSON(value.hydropowerPlant),
    };
}


