import React from "react";
import SiteLayout from "./sitelayout";

export default ({ children, pageContext, location }) => {
    if (pageContext.layout === "none") {
        return (<div>{children}</div>);
    }
    if (pageContext.layout === "site") {
        return (<SiteLayout location={location}>{children}</SiteLayout>);
    }
    return (<div>{children}</div>);
};
