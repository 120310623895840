/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Customer,
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
    StringStringKeyValuePair,
    StringStringKeyValuePairFromJSON,
    StringStringKeyValuePairFromJSONTyped,
    StringStringKeyValuePairToJSON,
} from './';

/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    customerId?: string;
    /**
     * 
     * @type {Customer}
     * @memberof Admin
     */
    customer?: Customer | null;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    description?: string | null;
    /**
     * 
     * @type {object}
     * @memberof Admin
     */
    properties?: object | null;
    /**
     * 
     * @type {Date}
     * @memberof Admin
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Admin
     */
    updated?: Date;
    /**
     * 
     * @type {Array<StringStringKeyValuePair>}
     * @memberof Admin
     */
    values?: Array<StringStringKeyValuePair> | null;
}

export function AdminFromJSON(json: any): Admin {
    return AdminFromJSONTyped(json, false);
}

export function AdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): Admin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'customer': !exists(json, 'customer') ? undefined : CustomerFromJSON(json['customer']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'values': !exists(json, 'values') ? undefined : (json['values'] === null ? null : (json['values'] as Array<any>).map(StringStringKeyValuePairFromJSON)),
    };
}

export function AdminToJSON(value?: Admin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'customer': CustomerToJSON(value.customer),
        'email': value.email,
        'name': value.name,
        'description': value.description,
        'properties': value.properties,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'values': value.values === undefined ? undefined : (value.values === null ? null : (value.values as Array<any>).map(StringStringKeyValuePairToJSON)),
    };
}


