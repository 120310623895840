/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KinetikData
 */
export interface KinetikData {
    /**
     * 
     * @type {Date}
     * @memberof KinetikData
     */
    timestamp?: Date;
    /**
     * 
     * @type {number}
     * @memberof KinetikData
     */
    production?: number | null;
}

export function KinetikDataFromJSON(json: any): KinetikData {
    return KinetikDataFromJSONTyped(json, false);
}

export function KinetikDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): KinetikData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'production': !exists(json, 'production') ? undefined : json['production'],
    };
}

export function KinetikDataToJSON(value?: KinetikData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'production': value.production,
    };
}


