/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KinetikData,
    KinetikDataFromJSON,
    KinetikDataFromJSONTyped,
    KinetikDataToJSON,
    Sensor,
    SensorFromJSON,
    SensorFromJSONTyped,
    SensorToJSON,
    State,
    StateFromJSON,
    StateFromJSONTyped,
    StateToJSON,
} from './';

/**
 * 
 * @export
 * @interface Generator
 */
export interface Generator {
    /**
     * 
     * @type {string}
     * @memberof Generator
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Generator
     */
    description?: string | null;
    /**
     * 
     * @type {KinetikData}
     * @memberof Generator
     */
    kinetikData?: KinetikData | null;
    /**
     * 
     * @type {Array<Sensor>}
     * @memberof Generator
     */
    sensors?: Array<Sensor> | null;
    /**
     * 
     * @type {Array<State>}
     * @memberof Generator
     */
    states?: Array<State> | null;
}

export function GeneratorFromJSON(json: any): Generator {
    return GeneratorFromJSONTyped(json, false);
}

export function GeneratorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Generator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'kinetikData': !exists(json, 'kinetikData') ? undefined : KinetikDataFromJSON(json['kinetikData']),
        'sensors': !exists(json, 'sensors') ? undefined : (json['sensors'] === null ? null : (json['sensors'] as Array<any>).map(SensorFromJSON)),
        'states': !exists(json, 'states') ? undefined : (json['states'] === null ? null : (json['states'] as Array<any>).map(StateFromJSON)),
    };
}

export function GeneratorToJSON(value?: Generator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'kinetikData': KinetikDataToJSON(value.kinetikData),
        'sensors': value.sensors === undefined ? undefined : (value.sensors === null ? null : (value.sensors as Array<any>).map(SensorToJSON)),
        'states': value.states === undefined ? undefined : (value.states === null ? null : (value.states as Array<any>).map(StateToJSON)),
    };
}


