import { Reducer, useEffect } from 'react';

import * as signalR from "@microsoft/signalr";
import produce from 'immer';
import { createContainer } from 'react-tracked';
import { useReducerAsync } from 'use-reducer-async';
import { Customer, CustomerDataGetResponse, CustomerEventsCountGetResponse, CustomerEventsGetResponse, CustomerGetResponse, HydropowerPlant, HydropowerPlantSensorDataGetResponse, HydropowerPlantSensorDifferenceGetResponse, HydropowerPlantsGetResponse, KinetikData, KinetikEvent, KinetikEventCount, KinetikRealtimeCustomerMessage, KinetikRealtimePlantDataMessage, KinetikSensorData, LoginPostResponse, LoginVerifyRequest, LoginVerifyResponse, V1CustomerDataGetRequest, V1CustomerEventsCountGetRequest, V1CustomerEventsGetRequest, V1HydropowerPlantSensorSensorDataGetRequest, V1HydropowerPlantSensorSensorDifferenceGetRequest, V1LoginStartPostRequest, V1LoginVerifyPostRequest, V1HydropowerPlantHydropowerPlantIdStateElapsedGetRequest, HydropowerPlantStateElapsedGetResponse, StateElapsed, V1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGetRequest } from '../service';
import { KinetikRealtimePlantMessage } from '../service/models/KinetikRealtimePlantMessage';
import { asyncActionHandlers, validateToken } from './actions';
import { HydropowerPlantSensorPredictionGetResponse } from '../service/models/HydropowerPlantSensorPredictionGetResponse';
import { PredictionItem } from '../service/models/PredictionItem';

const windowGlobal = typeof window !== 'undefined' && window;

const LOCAL_STORAGE_KEY = 'storage';
export enum ActionTypes {
    SET_DASHBOARD = 'SET_DASHBOARD',
    RELOAD_DATA = 'RELOAD_DATA',
    USER_LOGOUT = 'USER_LOGOUT',
    LOGIN_START_STARTED = 'LOGIN_START_STARTED',
    LOGIN_STARTED = 'LOGIN_STARTED',
    LOGIN_START_FAILED = 'LOGIN_START_FAILED',
    TOKEN_VALIDATE = 'TOKEN_VALIDATE',
    TOKEN_VALID = 'TOKEN_VALID',
    TOKEN_INVALID = 'TOKEN_INVALID',
    LOGIN_VERIFY_STARTED = "LOGIN_VERIFY_STARTED",
    LOGIN_VERIFY_SUCCESS = "LOGIN_VERIFY_SUCCESS",
    LOGIN_VERIFY_FAILED = "LOGIN_VERIFY_FAILED",
    LOGIN_START_SUCCESS = "LOGIN_START_SUCCESS",
    MAP_POSITION_CHANGE = "MAP_POSITION_CHANGE",
    RELOAD_DATA_STARTED = "RELOAD_DATA_STARTED",
    RELOAD_DATA_SUCCESS = "RELOAD_DATA_SUCCESS",
    RELOAD_DATA_FAILED = "RELOAD_DATA_FAILED",
    LOGOUT_STARTED = "LOGOUT_STARTED",
    LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
    LOGOUT_FAILED = "LOGOUT_FAILED",
    GET_HYDROPOWERPLANTS_START = "GET_HYDROPOWERPLANTS_START",
    GET_HYDROPOWERPLANTS_SUCCESS = "GET_HYDROPOWERPLANTS_SUCCESS",
    GET_HYDROPOWERPLANTS_FAILED = "GET_HYDROPOWERPLANTS_FAILED",
    GET_HYDROPOWERREALTIMEPLANT_SUCCESS = "GET_HYDROPOWERREALTIMEPLANT_SUCCESS",
    GET_HYDROPOWERREALTIMEPLANTDATA_SUCCESS = "GET_HYDROPOWERREALTIMEPLANTDATA_SUCCESS",
    GET_HYDROPOWERREALTIMECUSTOMERDATA_SUCCESS = "GET_HYDROPOWERREALTIMECUSTOMERDATA_SUCCESS",
    GET_CUSTOMER_START = "GET_CUSTOMER_START",
    GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS",
    GET_CUSTOMER_FAILED = "GET_CUSTOMER_FAILED",
    GET_CUSTOMERDATA_START = "GET_CUSTOMERDATA_START",
    GET_CUSTOMERDATA_SUCCESS = "GET_CUSTOMERDATA_SUCCESS",
    GET_CUSTOMERDATA_FAILED = "GET_CUSTOMERDATA_FAILED",
    GET_CUSTOMEREVENTS_START = "GET_CUSTOMEREVENTS_START",
    GET_CUSTOMEREVENTS_SUCCESS = "GET_CUSTOMEREVENTS_SUCCESS",
    GET_CUSTOMEREVENTS_FAILED = "GET_CUSTOMEREVENTS_FAILED",
    GET_CUSTOMEREVENTSDAY_START = "GET_CUSTOMEREVENTS_START",
    GET_CUSTOMEREVENTSDAY_SUCCESS = "GET_CUSTOMEREVENTS_SUCCESS",
    GET_CUSTOMEREVENTSDAY_FAILED = "GET_CUSTOMEREVENTS_FAILED",
    GET_CUSTOMEREVENTSCOUNT_SUCCESS = "GET_CUSTOMEREVENTSCOUNT_SUCCESS",
    GET_CUSTOMEREVENTSCOUNT_START = "GET_CUSTOMEREVENTSCOUNT_START",
    GET_CUSTOMEREVENTSCOUNT_FAILED = "GET_CUSTOMEREVENTSCOUNT_FAILED",
    GET_CUSTOMEREVENTSCOUNTDAY_SUCCESS = "GET_CUSTOMEREVENTSCOUNTDAY_SUCCESS",
    GET_CUSTOMEREVENTSCOUNTDAY_START = "GET_CUSTOMEREVENTSCOUNTDAY_START",
    GET_CUSTOMEREVENTSCOUNTDAY_FAILED = "GET_CUSTOMEREVENTSCOUNTDAY_FAILED",
    GET_HYDROPOWERPLANTSENSORDATA_START = "GET_HYDROPOWERPLANTSENSORDATA_START",
    GET_HYDROPOWERPLANTSENSORDATA_SUCCESS = "GET_HYDROPOWERPLANTSENSORDATA_SUCCESS",
    GET_HYDROPOWERPLANTSENSORDATA_FAILED = "GET_HYDROPOWERPLANTSENSORDATA_FAILED",
    GET_HYDROPOWERPLANTSENSORDIFFERENCE_START = "GET_HYDROPOWERPLANTSENSORDIFFERENCE_START",
    GET_HYDROPOWERPLANTSENSORDIFFERENCE_SUCCESS = "GET_HYDROPOWERPLANTSENSORDIFFERENCE_SUCCESS",
    GET_HYDROPOWERPLANTSENSORDIFFERENCE_FAILED = "GET_HYDROPOWERPLANTSENSORDIFFERENCE_FAILED",
    GET_HYDROPOWERPLANTSTATEELAPSED_START = "GET_HYDROPOWERPLANTSTATEELAPSED_START",
    GET_HYDROPOWERPLANTSTATEELAPSED_SUCCESS = "GET_HYDROPOWERPLANTSTATEELAPSED_SUCCESS",
    GET_HYDROPOWERPLANTSTATEELAPSED_FAILED = "GET_HYDROPOWERPLANTSTATEELAPSED_FAILED",
    SET_LOCATION = "SET_LOCATION",
    GET_HYDROPOWERPLANTSENSORPREDICTION_START = "GET_HYDROPOWERPLANTSENSORPREDICTION_START",
    GET_HYDROPOWERPLANTSENSORPREDICTION_SUCCESS = "GET_HYDROPOWERPLANTSENSORPREDICTION_SUCCESS",
    GET_HYDROPOWERPLANTSENSORPREDICTION_FAILED = "GET_HYDROPOWERPLANTSENSORPREDICTION_FAILED"
}

export type Action =
    | { type: ActionTypes.GET_HYDROPOWERPLANTSENSORPREDICTION_START }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSENSORPREDICTION_SUCCESS, data: HydropowerPlantSensorPredictionGetResponse }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSENSORPREDICTION_FAILED, message: string }
    | { type: ActionTypes.SET_LOCATION, location: string }
    | { type: ActionTypes.SET_DASHBOARD, data: IDashboardState, hydropowerPlant: HydropowerPlant }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSENSORDIFFERENCE_START }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSENSORDIFFERENCE_SUCCESS, data: HydropowerPlantSensorDifferenceGetResponse }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSENSORDIFFERENCE_FAILED, message: string }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSENSORDATA_START }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSENSORDATA_SUCCESS, data: HydropowerPlantSensorDataGetResponse }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSENSORDATA_FAILED, message: string }
    | { type: ActionTypes.GET_CUSTOMEREVENTSCOUNT_START }
    | { type: ActionTypes.GET_CUSTOMEREVENTSCOUNT_SUCCESS, data: CustomerEventsCountGetResponse }
    | { type: ActionTypes.GET_CUSTOMEREVENTSCOUNT_FAILED, message: string }
    | { type: ActionTypes.GET_CUSTOMEREVENTSCOUNTDAY_START }
    | { type: ActionTypes.GET_CUSTOMEREVENTSCOUNTDAY_SUCCESS, data: CustomerEventsCountGetResponse }
    | { type: ActionTypes.GET_CUSTOMEREVENTSCOUNTDAY_FAILED, message: string }
    | { type: ActionTypes.GET_CUSTOMEREVENTS_START }
    | { type: ActionTypes.GET_CUSTOMEREVENTS_SUCCESS, data: CustomerEventsGetResponse }
    | { type: ActionTypes.GET_CUSTOMEREVENTS_FAILED, message: string }
    | { type: ActionTypes.GET_CUSTOMERDATA_START }
    | { type: ActionTypes.GET_CUSTOMERDATA_SUCCESS, data: CustomerDataGetResponse }
    | { type: ActionTypes.GET_CUSTOMERDATA_FAILED, message: string }
    | { type: ActionTypes.GET_CUSTOMER_START }
    | { type: ActionTypes.GET_CUSTOMER_SUCCESS, data: CustomerGetResponse }
    | { type: ActionTypes.GET_CUSTOMER_FAILED, message: string }
    | { type: ActionTypes.GET_HYDROPOWERREALTIMEPLANT_SUCCESS, data: KinetikRealtimePlantMessage }
    | { type: ActionTypes.GET_HYDROPOWERREALTIMEPLANTDATA_SUCCESS, data: KinetikRealtimePlantDataMessage }
    | { type: ActionTypes.GET_HYDROPOWERREALTIMECUSTOMERDATA_SUCCESS, data: KinetikRealtimeCustomerMessage }
    | { type: ActionTypes.GET_HYDROPOWERPLANTS_START }
    | { type: ActionTypes.GET_HYDROPOWERPLANTS_SUCCESS, data: HydropowerPlantsGetResponse }
    | { type: ActionTypes.GET_HYDROPOWERPLANTS_FAILED, message: string }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSTATEELAPSED_START }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSTATEELAPSED_SUCCESS, data: HydropowerPlantStateElapsedGetResponse }
    | { type: ActionTypes.GET_HYDROPOWERPLANTSTATEELAPSED_FAILED, message: string }
    | { type: ActionTypes.MAP_POSITION_CHANGE, data: IMapViewState }
    | { type: ActionTypes.LOGIN_VERIFY_STARTED }
    | { type: ActionTypes.LOGIN_VERIFY_FAILED, message: string }
    | { type: ActionTypes.LOGIN_VERIFY_SUCCESS, data: LoginVerifyResponse }
    | { type: ActionTypes.USER_LOGOUT }
    | { type: ActionTypes.TOKEN_VALIDATE }
    | { type: ActionTypes.TOKEN_VALID }
    | { type: ActionTypes.TOKEN_INVALID }
    | { type: ActionTypes.LOGIN_START_SUCCESS, data: LoginPostResponse }
    | { type: ActionTypes.LOGIN_START_STARTED }
    | { type: ActionTypes.RELOAD_DATA }
    | { type: ActionTypes.LOGOUT_SUCCESS }
    | { type: ActionTypes.LOGIN_START_FAILED, message: string }
    | { type: 'signalrConnected' }
    | { type: 'signalrDisconnected' }

export type AsyncAction =
    | { type: 'GET_HYDROPOWERPLANTSENSORPREDICTION', request: V1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGetRequest; }
    | { type: 'GET_HYDROPOWERPLANTSTATEELAPSED', request: V1HydropowerPlantHydropowerPlantIdStateElapsedGetRequest; }
    | { type: 'GET_CUSTOMEREVENTSCOUNTDAY', request: V1CustomerEventsCountGetRequest; }
    | { type: 'GET_CUSTOMEREVENTSCOUNT', request: V1CustomerEventsCountGetRequest; }
    | { type: 'GET_CUSTOMEREVENTS', request: V1CustomerEventsGetRequest; }
    | { type: 'GET_HYDROPOWERPLANTSENSORDATA', request: V1HydropowerPlantSensorSensorDataGetRequest; }
    | { type: 'GET_HYDROPOWERPLANTSENSORDIFFERENCE', request: V1HydropowerPlantSensorSensorDifferenceGetRequest; }
    | { type: 'GET_CUSTOMERDATA', request: V1CustomerDataGetRequest; }
    | { type: 'GET_CUSTOMER'; }
    | { type: 'GET_HYDROPOWERPLANTS'; }
    | { type: 'LOGIN_START'; request: V1LoginStartPostRequest }
    | { type: 'LOGIN_VERIFY'; request: V1LoginVerifyPostRequest };

export interface IPersistedState {
    authId?: string;
    token?: string;
    mapViewState: IMapViewState;
}

export interface ILoginState {
    isAuthorized: boolean;
    isLoading: boolean;
    isLoggingIn: boolean;
    isWaitingForVerify: boolean;
    isUnauthorized: boolean;
    isInvalidAuthCode: boolean;
    isVerifyingCode: boolean;
}

export interface IMapViewState {
    latitude: number;
    longitude: number;
    zoom: number;
    pitch: number;
    bearing: number;
}

export interface IDashboardState {
    plantDetailsActive: boolean;
}

export interface ILoadingProgressState {
    items: ILoadingProgressStateItem[];
}

export interface ILoadingProgressStateItem {
    id: string;
    description: string;
    hasLoaded: boolean;
}

export interface IState {
    location: string;
    dashboard: IDashboardState;
    loadingprogress: ILoadingProgressState;
    customerdata: KinetikData[];
    customer: Customer;
    hydropowerplant: HydropowerPlant;
    hydropowerplants: HydropowerPlant[];
    login: ILoginState;
    isConnectedToSignalR: boolean;
    persisted: IPersistedState;
    customerevents: KinetikEvent[];
    customereventscount: KinetikEventCount[];
    customereventscountday: KinetikEventCount[];
    hydropowerplantsensordata: KinetikSensorData[];
    hydropowerplantsensordifference: KinetikSensorData[];
    hydropowerplantstateelapsed: StateElapsed[];
    hydropowerplantprediction: PredictionItem[];
    hydropowerrealtimeplant: KinetikRealtimePlantMessage[]; // Contains realtime raw data from the plants
    hydropowerrealtimeplantlatest: KinetikRealtimePlantMessage[]; // Contains the latest realtime plant message from ech plant
    hydropowerrealtimeplantdata: KinetikRealtimePlantDataMessage[]; // Contains realtime parsed data from plants
    hydropowerrealtimeplantdatalatest: KinetikRealtimePlantDataMessage[]; // Contains latest realtime data from plants
    hydropowerrealtimecustomerdata: KinetikRealtimeCustomerMessage[]; // Contains realtime parsed data from customers

}

const initialState: IState = {
    location: null,
    isConnectedToSignalR: false,
    login: { isLoggingIn: false, isWaitingForVerify: false, isUnauthorized: false, isInvalidAuthCode: false, isLoading: false, isVerifyingCode: false, isAuthorized: false },
    dashboard: {
        plantDetailsActive: false,
    },
    persisted: {
        mapViewState: {
            latitude: 59.744429,
            longitude: 10.199681,
            zoom: 12,
            pitch: 0,
            bearing: 0
        },
    },
    hydropowerplant: {
        id: "0000000000000000000000000", name: "", description: "", sensors: [], states: [], properties: {
            coordinates: {
                latitude: 59.744429,
                longitude: 10.199681,
            }
        }
    },
    customerdata: [],
    customer: {},
    customerevents: [],
    hydropowerplantsensordata: [],
    hydropowerplantsensordifference: [],
    hydropowerplants: [],
    hydropowerplantprediction: [],
    loadingprogress: { items: [] },
    hydropowerrealtimecustomerdata: [],
    customereventscount: [],
    customereventscountday: [],
    hydropowerrealtimeplant: [],
    hydropowerrealtimeplantdata: [],
    hydropowerrealtimeplantlatest: [],
    hydropowerrealtimeplantdatalatest: [],
    hydropowerplantstateelapsed: [],
};

const reducer: Reducer<IState, Action> = (state, action) => {
    const nextState = produce(state, (draft) => {

        function update(type: ActionTypes, description: string, status: boolean) {
            const found = draft.loadingprogress.items.findIndex((item) => item.id === type);
            if (found !== -1) {
                draft.loadingprogress.items[found].hasLoaded = status;
            } else {
                draft.loadingprogress.items.push({ id: type, description, hasLoaded: status });
            }
        }

        switch (action.type) {

            case ActionTypes.SET_LOCATION:
                draft.location = action.location;
                validateToken(state.persisted.token, action.location);
                break;

            // Dashboard
            case ActionTypes.SET_DASHBOARD:
                /*draft.dashboard = action.data;*/
                if (action.hydropowerPlant) {
                    draft.hydropowerplant = action.hydropowerPlant;
                }
                break;

            // Login
            case ActionTypes.LOGIN_START_STARTED:
                draft.login.isLoading = true;
                break;
            case ActionTypes.LOGIN_START_SUCCESS:
                draft.login.isWaitingForVerify = true;
                draft.login.isUnauthorized = false;
                draft.persisted.authId = action.data.authId;
                draft.login.isLoading = false;
                break;
            case ActionTypes.LOGIN_VERIFY_STARTED:
                draft.login.isVerifyingCode = true;
                draft.login.isLoading = true;
                break;
            case ActionTypes.LOGIN_START_FAILED:
                draft.login.isUnauthorized = true;
                draft.login.isLoading = false;
                break;
            case ActionTypes.LOGIN_VERIFY_FAILED:
                draft.login.isInvalidAuthCode = true;
                draft.login.isLoading = false;
                draft.login.isVerifyingCode = false;
                break;
            case ActionTypes.LOGIN_VERIFY_SUCCESS:
                draft.persisted.token = action.data.token;
                draft.login.isLoading = false;
                draft.login.isAuthorized = true;
                break;
            case ActionTypes.LOGOUT_SUCCESS:
                draft.login.isVerifyingCode = false;
                draft.login.isWaitingForVerify = false;
                draft.persisted.token = null;
                break;

            // Map
            case ActionTypes.MAP_POSITION_CHANGE:
                draft.persisted.mapViewState = action.data;
                break;

            // Hydropowerplant
            case ActionTypes.GET_HYDROPOWERPLANTS_SUCCESS:
                draft.hydropowerplants = action.data.hydropowerPlants;
                break;

            // HydropowerplantSensorData
            case ActionTypes.GET_HYDROPOWERPLANTSENSORDATA_SUCCESS:
                draft.hydropowerplantsensordata = action.data.data;
                break;

            // HydropowerplantSensorData
            case ActionTypes.GET_HYDROPOWERPLANTSENSORDIFFERENCE_SUCCESS:
                draft.hydropowerplantsensordifference = action.data.data;
                break;

            // HydropowerplantSensorPrediction
            case ActionTypes.GET_HYDROPOWERPLANTSENSORPREDICTION_SUCCESS:
                draft.hydropowerplantprediction = action.data.predictions;
                break;

            // HydropowerplantStateElapsed
            case ActionTypes.GET_HYDROPOWERPLANTSTATEELAPSED_SUCCESS:
                draft.hydropowerplantstateelapsed = action.data.stateElapsed;
                break;

            // Customer
            case ActionTypes.GET_CUSTOMER_SUCCESS:
                draft.customer = action.data.customer;
                break;
            case ActionTypes.GET_CUSTOMERDATA_SUCCESS:
                draft.customerdata = action.data.data;
                break;
            case ActionTypes.GET_CUSTOMEREVENTS_SUCCESS:
                draft.customerevents = action.data.kinetikEvents;
                break;
            case ActionTypes.GET_CUSTOMEREVENTSCOUNT_SUCCESS:
                draft.customereventscount = action.data.kinetikEventsCount;
                break;
            case ActionTypes.GET_CUSTOMEREVENTSCOUNTDAY_SUCCESS:
                draft.customereventscountday = action.data.kinetikEventsCount;
                break;

            // Realtime
            case ActionTypes.GET_HYDROPOWERREALTIMECUSTOMERDATA_SUCCESS:
                if (draft.hydropowerrealtimecustomerdata.length >= 20) { draft.hydropowerrealtimecustomerdata.shift(); }
                draft.hydropowerrealtimecustomerdata.push(action.data);
                break;
            case ActionTypes.GET_HYDROPOWERREALTIMEPLANTDATA_SUCCESS:
                if (draft.hydropowerrealtimeplantdata.length >= 20) { draft.hydropowerrealtimeplantdata.shift(); }

                // Set last
                const isInLatestPlantData = draft.hydropowerrealtimeplantdatalatest.findIndex((latest) => latest.id === action.data.id);
                if (isInLatestPlantData !== -1) {
                    draft.hydropowerrealtimeplantdatalatest[draft.hydropowerrealtimeplantdatalatest.findIndex((latest) => latest.id === action.data.id)] = action.data;
                } else {
                    draft.hydropowerrealtimeplantdatalatest.push(action.data);
                }

                // push to history
                draft.hydropowerrealtimeplantdata.push(action.data);
                break;
            case ActionTypes.GET_HYDROPOWERREALTIMEPLANT_SUCCESS:
                if (draft.hydropowerrealtimeplant.length >= 2000) { draft.hydropowerrealtimeplant.shift(); }

                // Set last
                const isInLatest = draft.hydropowerrealtimeplantlatest.findIndex((latest) => latest.id === action.data.id);
                if (isInLatest !== -1) {
                    draft.hydropowerrealtimeplantlatest[draft.hydropowerrealtimeplantlatest.findIndex((latest) => latest.id === action.data.id)] = action.data;
                } else {
                    draft.hydropowerrealtimeplantlatest.push(action.data);
                }

                // Push to historu
                draft.hydropowerrealtimeplant.push(action.data);
                break;

            // Progress
            case ActionTypes.GET_HYDROPOWERPLANTSENSORDIFFERENCE_START:
                update(ActionTypes.GET_HYDROPOWERPLANTSENSORDIFFERENCE_SUCCESS, "sensordiff", false);
                break;
            case ActionTypes.GET_HYDROPOWERPLANTSENSORDATA_START:
                update(ActionTypes.GET_HYDROPOWERPLANTSENSORDATA_SUCCESS, "sensordata", false);
                break;
            case ActionTypes.GET_CUSTOMERDATA_START:
                update(ActionTypes.GET_CUSTOMERDATA_SUCCESS, "produksjonsdata", false);
                break;
            case ActionTypes.GET_CUSTOMEREVENTS_START:
                update(ActionTypes.GET_CUSTOMEREVENTS_SUCCESS, "hendelser", false);
                break;
            case ActionTypes.GET_CUSTOMEREVENTSCOUNT_START:
                update(ActionTypes.GET_CUSTOMEREVENTSCOUNT_SUCCESS, "summerte hendelser", false);
                break;

            // Validate
            case ActionTypes.TOKEN_VALIDATE:
                const isValid = validateToken(state.persisted.token, state.location);
                break;

            case ActionTypes.GET_HYDROPOWERPLANTSENSORDIFFERENCE_SUCCESS:
            case ActionTypes.GET_HYDROPOWERPLANTSENSORDATA_SUCCESS:
            case ActionTypes.GET_CUSTOMEREVENTSCOUNT_SUCCESS:
            case ActionTypes.GET_CUSTOMEREVENTS_SUCCESS:
            case ActionTypes.GET_CUSTOMERDATA_SUCCESS:
                draft.loadingprogress.items[draft.loadingprogress.items.findIndex((item) => item.id === action.type)].hasLoaded = true;
                break;

            case 'signalrConnected':
                draft.isConnectedToSignalR = true;
                break;
            case 'signalrDisconnected':
                draft.isConnectedToSignalR = false;
                break;
        }
    });
    return nextState;
};

const useValue = () => {

    const [state, useDispatch] = useReducerAsync<Reducer<IState, Action>, AsyncAction>(reducer, initialState, asyncActionHandlers);

    const init = () => {
        const _state: IState = initialState;
        let _persisted: IPersistedState;
        try {
            _persisted = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
            if (_persisted) {
                _state.persisted = _persisted;
            }
            // validate preloadedState if necessary
        } catch (e) {
            // ignore
        }
        useDispatch({ type: ActionTypes.TOKEN_VALIDATE });
        return _state || initialState;
    };

    useEffect(() => {
        init();
    }, []);

    let iAmConnected = false;

    useEffect(() => {
        if (!iAmConnected) {
            signalrManager(state, useDispatch);
            iAmConnected = true;
        }
    }, [state.persisted]);

    useEffect(() => {
        if (windowGlobal) {
            if (!state.persisted) { state.persisted = initialState.persisted; }
            windowGlobal.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state.persisted));
        }
    }, [state.persisted]);

    return [state, useDispatch] as const;
};

// @ts-ignore
export const { Provider, useTracked, useTrackedState, useUpdate: useDispatch } = createContainer(useValue);

const signalrManager = (state: IState, dispatch) => {
    try {
        if (!state.isConnectedToSignalR) {
            if (!state.persisted.token) { return; }
            const connection = new signalR.HubConnectionBuilder().withUrl(process.env.GATSBY_KINETIKAPI_URL + "/realtime", { accessTokenFactory: () => state.persisted.token }).build();

            connection.on("hydropowerRealtimePlantDataMessage", (data) => {
                dispatch({ type: ActionTypes.GET_HYDROPOWERREALTIMEPLANTDATA_SUCCESS, data });
            });

            connection.on("hydropowerRealtimeCustomerDataMessage", (data) => {
                dispatch({ type: ActionTypes.GET_HYDROPOWERREALTIMECUSTOMERDATA_SUCCESS, data });
            });

            connection.on("hydropowerRealtimePlantMessage", (data) => {
                dispatch({ type: ActionTypes.GET_HYDROPOWERREALTIMEPLANT_SUCCESS, data });
            });

            connection.start().catch((err) => {
                //console.log("Failed to connect to signalR", err);
            });

            dispatch({ type: 'signalrConnected' });
        }
    } catch (err) {
        //console.log("Could not start the connection to SignalR", err);
    }
};
