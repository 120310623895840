/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginVerifyRequest
 */
export interface LoginVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginVerifyRequest
     */
    authId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginVerifyRequest
     */
    authCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoginVerifyRequest
     */
    rememberMe?: boolean;
}

export function LoginVerifyRequestFromJSON(json: any): LoginVerifyRequest {
    return LoginVerifyRequestFromJSONTyped(json, false);
}

export function LoginVerifyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginVerifyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authId': !exists(json, 'authId') ? undefined : json['authId'],
        'authCode': !exists(json, 'authCode') ? undefined : json['authCode'],
        'rememberMe': !exists(json, 'rememberMe') ? undefined : json['rememberMe'],
    };
}

export function LoginVerifyRequestToJSON(value?: LoginVerifyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authId': value.authId,
        'authCode': value.authCode,
        'rememberMe': value.rememberMe,
    };
}


