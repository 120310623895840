/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LoginPostRequest,
    LoginPostRequestFromJSON,
    LoginPostRequestToJSON,
    LoginPostResponse,
    LoginPostResponseFromJSON,
    LoginPostResponseToJSON,
    LoginVerifyRequest,
    LoginVerifyRequestFromJSON,
    LoginVerifyRequestToJSON,
    LoginVerifyResponse,
    LoginVerifyResponseFromJSON,
    LoginVerifyResponseToJSON,
} from '../models';

export interface V1LoginStartPostRequest {
    loginPostRequest?: LoginPostRequest;
}

export interface V1LoginVerifyPostRequest {
    loginVerifyRequest?: LoginVerifyRequest;
}

/**
 * no description
 */
export class LoginApi extends runtime.BaseAPI {

    /**
     */
    async v1LoginStartPostRaw(requestParameters: V1LoginStartPostRequest): Promise<runtime.ApiResponse<LoginPostResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/Login/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginPostRequestToJSON(requestParameters.loginPostRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginPostResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1LoginStartPost(requestParameters: V1LoginStartPostRequest): Promise<LoginPostResponse> {
        const response = await this.v1LoginStartPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async v1LoginVerifyPostRaw(requestParameters: V1LoginVerifyPostRequest): Promise<runtime.ApiResponse<LoginVerifyResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/Login/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginVerifyRequestToJSON(requestParameters.loginVerifyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginVerifyResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1LoginVerifyPost(requestParameters: V1LoginVerifyPostRequest): Promise<LoginVerifyResponse> {
        const response = await this.v1LoginVerifyPostRaw(requestParameters);
        return await response.value();
    }

}
