/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PredictionItem,
    PredictionItemFromJSON,
    PredictionItemFromJSONTyped,
    PredictionItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface HydropowerPlantSensorPredictionGetResponse
 */
export interface HydropowerPlantSensorPredictionGetResponse {
    /**
     * 
     * @type {Array<PredictionItem>}
     * @memberof HydropowerPlantSensorPredictionGetResponse
     */
    predictions?: Array<PredictionItem> | null;
}

export function HydropowerPlantSensorPredictionGetResponseFromJSON(json: any): HydropowerPlantSensorPredictionGetResponse {
    return HydropowerPlantSensorPredictionGetResponseFromJSONTyped(json, false);
}

export function HydropowerPlantSensorPredictionGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydropowerPlantSensorPredictionGetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'predictions': !exists(json, 'predictions') ? undefined : (json['predictions'] === null ? null : (json['predictions'] as Array<any>).map(PredictionItemFromJSON)),
    };
}

export function HydropowerPlantSensorPredictionGetResponseToJSON(value?: HydropowerPlantSensorPredictionGetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'predictions': value.predictions === undefined ? undefined : (value.predictions === null ? null : (value.predictions as Array<any>).map(PredictionItemToJSON)),
    };
}


