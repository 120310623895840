/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KinetikData,
    KinetikDataFromJSON,
    KinetikDataFromJSONTyped,
    KinetikDataToJSON,
    Sensor,
    SensorFromJSON,
    SensorFromJSONTyped,
    SensorToJSON,
    State,
    StateFromJSON,
    StateFromJSONTyped,
    StateToJSON,
} from './';

/**
 * 
 * @export
 * @interface HydropowerData
 */
export interface HydropowerData {
    /**
     * 
     * @type {KinetikData}
     * @memberof HydropowerData
     */
    kinetikData?: KinetikData | null;
    /**
     * 
     * @type {Array<Sensor>}
     * @memberof HydropowerData
     */
    sensors?: Array<Sensor> | null;
    /**
     * 
     * @type {Array<State>}
     * @memberof HydropowerData
     */
    states?: Array<State> | null;
}

export function HydropowerDataFromJSON(json: any): HydropowerData {
    return HydropowerDataFromJSONTyped(json, false);
}

export function HydropowerDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydropowerData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kinetikData': !exists(json, 'kinetikData') ? undefined : KinetikDataFromJSON(json['kinetikData']),
        'sensors': !exists(json, 'sensors') ? undefined : (json['sensors'] === null ? null : (json['sensors'] as Array<any>).map(SensorFromJSON)),
        'states': !exists(json, 'states') ? undefined : (json['states'] === null ? null : (json['states'] as Array<any>).map(StateFromJSON)),
    };
}

export function HydropowerDataToJSON(value?: HydropowerData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kinetikData': KinetikDataToJSON(value.kinetikData),
        'sensors': value.sensors === undefined ? undefined : (value.sensors === null ? null : (value.sensors as Array<any>).map(SensorToJSON)),
        'states': value.states === undefined ? undefined : (value.states === null ? null : (value.states as Array<any>).map(StateToJSON)),
    };
}


