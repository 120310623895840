/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Coordinates,
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
    Generator,
    GeneratorFromJSON,
    GeneratorFromJSONTyped,
    GeneratorToJSON,
    Turbine,
    TurbineFromJSON,
    TurbineFromJSONTyped,
    TurbineToJSON,
} from './';

/**
 * 
 * @export
 * @interface HydropowerPlantProperties
 */
export interface HydropowerPlantProperties {
    /**
     * 
     * @type {Coordinates}
     * @memberof HydropowerPlantProperties
     */
    coordinates?: Coordinates | null;
    /**
     * 
     * @type {boolean}
     * @memberof HydropowerPlantProperties
     */
    hasMultipleTurbines?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HydropowerPlantProperties
     */
    hasMultipleGenerators?: boolean;
    /**
     * 
     * @type {Array<Turbine>}
     * @memberof HydropowerPlantProperties
     */
    turbines?: Array<Turbine> | null;
    /**
     * 
     * @type {Array<Generator>}
     * @memberof HydropowerPlantProperties
     */
    generators?: Array<Generator> | null;
}

export function HydropowerPlantPropertiesFromJSON(json: any): HydropowerPlantProperties {
    return HydropowerPlantPropertiesFromJSONTyped(json, false);
}

export function HydropowerPlantPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydropowerPlantProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coordinates': !exists(json, 'coordinates') ? undefined : CoordinatesFromJSON(json['coordinates']),
        'hasMultipleTurbines': !exists(json, 'hasMultipleTurbines') ? undefined : json['hasMultipleTurbines'],
        'hasMultipleGenerators': !exists(json, 'hasMultipleGenerators') ? undefined : json['hasMultipleGenerators'],
        'turbines': !exists(json, 'turbines') ? undefined : (json['turbines'] === null ? null : (json['turbines'] as Array<any>).map(TurbineFromJSON)),
        'generators': !exists(json, 'generators') ? undefined : (json['generators'] === null ? null : (json['generators'] as Array<any>).map(GeneratorFromJSON)),
    };
}

export function HydropowerPlantPropertiesToJSON(value?: HydropowerPlantProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coordinates': CoordinatesToJSON(value.coordinates),
        'hasMultipleTurbines': value.hasMultipleTurbines,
        'hasMultipleGenerators': value.hasMultipleGenerators,
        'turbines': value.turbines === undefined ? undefined : (value.turbines === null ? null : (value.turbines as Array<any>).map(TurbineToJSON)),
        'generators': value.generators === undefined ? undefined : (value.generators === null ? null : (value.generators as Array<any>).map(GeneratorToJSON)),
    };
}


