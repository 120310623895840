/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerDataGetResponse,
    CustomerDataGetResponseFromJSON,
    CustomerDataGetResponseToJSON,
    CustomerEventsCountGetResponse,
    CustomerEventsCountGetResponseFromJSON,
    CustomerEventsCountGetResponseToJSON,
    CustomerEventsGetResponse,
    CustomerEventsGetResponseFromJSON,
    CustomerEventsGetResponseToJSON,
    CustomerGetResponse,
    CustomerGetResponseFromJSON,
    CustomerGetResponseToJSON,
} from '../models';

export interface V1CustomerDataGetRequest {
    start?: number;
    end?: number;
    groupBy?: string;
}

export interface V1CustomerEventsCountGetRequest {
    start?: number;
    end?: number;
    groupBy?: string;
}

export interface V1CustomerEventsGetRequest {
    start?: number;
    end?: number;
    groupBy?: string;
}

/**
 * no description
 */
export class CustomerApi extends runtime.BaseAPI {

    /**
     */
    async v1CustomerDataGetRaw(requestParameters: V1CustomerDataGetRequest): Promise<runtime.ApiResponse<CustomerDataGetResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/Customer/data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerDataGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1CustomerDataGet(requestParameters: V1CustomerDataGetRequest): Promise<CustomerDataGetResponse> {
        const response = await this.v1CustomerDataGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async v1CustomerEventsCountGetRaw(requestParameters: V1CustomerEventsCountGetRequest): Promise<runtime.ApiResponse<CustomerEventsCountGetResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/Customer/events/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerEventsCountGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1CustomerEventsCountGet(requestParameters: V1CustomerEventsCountGetRequest): Promise<CustomerEventsCountGetResponse> {
        const response = await this.v1CustomerEventsCountGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async v1CustomerEventsGetRaw(requestParameters: V1CustomerEventsGetRequest): Promise<runtime.ApiResponse<CustomerEventsGetResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/Customer/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerEventsGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1CustomerEventsGet(requestParameters: V1CustomerEventsGetRequest): Promise<CustomerEventsGetResponse> {
        const response = await this.v1CustomerEventsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async v1CustomerGetRaw(): Promise<runtime.ApiResponse<CustomerGetResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/Customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1CustomerGet(): Promise<CustomerGetResponse> {
        const response = await this.v1CustomerGetRaw();
        return await response.value();
    }

}
