/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KinetikSensorData,
    KinetikSensorDataFromJSON,
    KinetikSensorDataFromJSONTyped,
    KinetikSensorDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface HydropowerPlantSensorDataGetResponse
 */
export interface HydropowerPlantSensorDataGetResponse {
    /**
     * 
     * @type {Array<KinetikSensorData>}
     * @memberof HydropowerPlantSensorDataGetResponse
     */
    data?: Array<KinetikSensorData> | null;
}

export function HydropowerPlantSensorDataGetResponseFromJSON(json: any): HydropowerPlantSensorDataGetResponse {
    return HydropowerPlantSensorDataGetResponseFromJSONTyped(json, false);
}

export function HydropowerPlantSensorDataGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydropowerPlantSensorDataGetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(KinetikSensorDataFromJSON)),
    };
}

export function HydropowerPlantSensorDataGetResponseToJSON(value?: HydropowerPlantSensorDataGetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(KinetikSensorDataToJSON)),
    };
}


