/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InputData,
    InputDataFromJSON,
    InputDataFromJSONTyped,
    InputDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface PredictionItem
 */
export interface PredictionItem {
    /**
     * 
     * @type {Date}
     * @memberof PredictionItem
     */
    timestamp?: Date;
    /**
     * 
     * @type {number}
     * @memberof PredictionItem
     */
    actual?: number;
    /**
     * 
     * @type {number}
     * @memberof PredictionItem
     */
    predicted?: number;
    /**
     * 
     * @type {Array<InputData>}
     * @memberof PredictionItem
     */
    inputData?: Array<InputData> | null;
}

export function PredictionItemFromJSON(json: any): PredictionItem {
    return PredictionItemFromJSONTyped(json, false);
}

export function PredictionItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'actual': !exists(json, 'actual') ? undefined : json['actual'],
        'predicted': !exists(json, 'predicted') ? undefined : json['predicted'],
        'inputData': !exists(json, 'inputData') ? undefined : (json['inputData'] === null ? null : (json['inputData'] as Array<any>).map(InputDataFromJSON)),
    };
}

export function PredictionItemToJSON(value?: PredictionItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'actual': value.actual,
        'predicted': value.predicted,
        'inputData': value.inputData === undefined ? undefined : (value.inputData === null ? null : (value.inputData as Array<any>).map(InputDataToJSON)),
    };
}


