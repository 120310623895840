/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Prop,
    PropFromJSON,
    PropFromJSONTyped,
    PropToJSON,
} from './';

/**
 * 
 * @export
 * @interface State
 */
export interface State {
    /**
     * 
     * @type {string}
     * @memberof State
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof State
     */
    active?: boolean;
    /**
     * 
     * @type {Array<Prop>}
     * @memberof State
     */
    props?: Array<Prop> | null;
}

export function StateFromJSON(json: any): State {
    return StateFromJSONTyped(json, false);
}

export function StateFromJSONTyped(json: any, ignoreDiscriminator: boolean): State {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'props': !exists(json, 'props') ? undefined : (json['props'] === null ? null : (json['props'] as Array<any>).map(PropFromJSON)),
    };
}

export function StateToJSON(value?: State | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'active': value.active,
        'props': value.props === undefined ? undefined : (value.props === null ? null : (value.props as Array<any>).map(PropToJSON)),
    };
}


