/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StateElapsed,
    StateElapsedFromJSON,
    StateElapsedFromJSONTyped,
    StateElapsedToJSON,
} from './';

/**
 * 
 * @export
 * @interface HydropowerPlantStateElapsedGetResponse
 */
export interface HydropowerPlantStateElapsedGetResponse {
    /**
     * 
     * @type {Array<StateElapsed>}
     * @memberof HydropowerPlantStateElapsedGetResponse
     */
    stateElapsed?: Array<StateElapsed> | null;
}

export function HydropowerPlantStateElapsedGetResponseFromJSON(json: any): HydropowerPlantStateElapsedGetResponse {
    return HydropowerPlantStateElapsedGetResponseFromJSONTyped(json, false);
}

export function HydropowerPlantStateElapsedGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydropowerPlantStateElapsedGetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stateElapsed': !exists(json, 'stateElapsed') ? undefined : (json['stateElapsed'] === null ? null : (json['stateElapsed'] as Array<any>).map(StateElapsedFromJSON)),
    };
}

export function HydropowerPlantStateElapsedGetResponseToJSON(value?: HydropowerPlantStateElapsedGetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stateElapsed': value.stateElapsed === undefined ? undefined : (value.stateElapsed === null ? null : (value.stateElapsed as Array<any>).map(StateElapsedToJSON)),
    };
}


