/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KinetikEvent,
    KinetikEventFromJSON,
    KinetikEventFromJSONTyped,
    KinetikEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerEventsGetResponse
 */
export interface CustomerEventsGetResponse {
    /**
     * 
     * @type {Array<KinetikEvent>}
     * @memberof CustomerEventsGetResponse
     */
    kinetikEvents?: Array<KinetikEvent> | null;
}

export function CustomerEventsGetResponseFromJSON(json: any): CustomerEventsGetResponse {
    return CustomerEventsGetResponseFromJSONTyped(json, false);
}

export function CustomerEventsGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerEventsGetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kinetikEvents': !exists(json, 'kinetikEvents') ? undefined : (json['kinetikEvents'] === null ? null : (json['kinetikEvents'] as Array<any>).map(KinetikEventFromJSON)),
    };
}

export function CustomerEventsGetResponseToJSON(value?: CustomerEventsGetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kinetikEvents': value.kinetikEvents === undefined ? undefined : (value.kinetikEvents === null ? null : (value.kinetikEvents as Array<any>).map(KinetikEventToJSON)),
    };
}


