/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HydropowerPlant,
    HydropowerPlantFromJSON,
    HydropowerPlantFromJSONTyped,
    HydropowerPlantToJSON,
} from './';

/**
 * 
 * @export
 * @interface HydropowerPlantsGetResponse
 */
export interface HydropowerPlantsGetResponse {
    /**
     * 
     * @type {Array<HydropowerPlant>}
     * @memberof HydropowerPlantsGetResponse
     */
    hydropowerPlants?: Array<HydropowerPlant> | null;
}

export function HydropowerPlantsGetResponseFromJSON(json: any): HydropowerPlantsGetResponse {
    return HydropowerPlantsGetResponseFromJSONTyped(json, false);
}

export function HydropowerPlantsGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydropowerPlantsGetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hydropowerPlants': !exists(json, 'hydropowerPlants') ? undefined : (json['hydropowerPlants'] === null ? null : (json['hydropowerPlants'] as Array<any>).map(HydropowerPlantFromJSON)),
    };
}

export function HydropowerPlantsGetResponseToJSON(value?: HydropowerPlantsGetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hydropowerPlants': value.hydropowerPlants === undefined ? undefined : (value.hydropowerPlants === null ? null : (value.hydropowerPlants as Array<any>).map(HydropowerPlantToJSON)),
    };
}


