/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputData
 */
export interface InputData {
    /**
     * 
     * @type {string}
     * @memberof InputData
     */
    sensorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InputData
     */
    value?: number;
}

export function InputDataFromJSON(json: any): InputData {
    return InputDataFromJSONTyped(json, false);
}

export function InputDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sensorName': !exists(json, 'sensorName') ? undefined : json['sensorName'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function InputDataToJSON(value?: InputData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sensorName': value.sensorName,
        'value': value.value,
    };
}


