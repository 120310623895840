/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KinetikEvent
 */
export interface KinetikEvent {
    /**
     * 
     * @type {string}
     * @memberof KinetikEvent
     */
    hydropowerPlantId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KinetikEvent
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KinetikEvent
     */
    state?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof KinetikEvent
     */
    timestamp?: Date;
}

export function KinetikEventFromJSON(json: any): KinetikEvent {
    return KinetikEventFromJSONTyped(json, false);
}

export function KinetikEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): KinetikEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hydropowerPlantId': !exists(json, 'hydropowerPlantId') ? undefined : json['hydropowerPlantId'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
    };
}

export function KinetikEventToJSON(value?: KinetikEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hydropowerPlantId': value.hydropowerPlantId,
        'active': value.active,
        'state': value.state,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
    };
}


