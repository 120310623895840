/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HydropowerPlant,
    HydropowerPlantFromJSON,
    HydropowerPlantToJSON,
    HydropowerPlantSensorDataGetResponse,
    HydropowerPlantSensorDataGetResponseFromJSON,
    HydropowerPlantSensorDataGetResponseToJSON,
    HydropowerPlantSensorDifferenceGetResponse,
    HydropowerPlantSensorDifferenceGetResponseFromJSON,
    HydropowerPlantSensorDifferenceGetResponseToJSON,
    HydropowerPlantSensorPredictionGetResponse,
    HydropowerPlantSensorPredictionGetResponseFromJSON,
    HydropowerPlantSensorPredictionGetResponseToJSON,
    HydropowerPlantStateElapsedGetResponse,
    HydropowerPlantStateElapsedGetResponseFromJSON,
    HydropowerPlantStateElapsedGetResponseToJSON,
    HydropowerPlantsGetResponse,
    HydropowerPlantsGetResponseFromJSON,
    HydropowerPlantsGetResponseToJSON,
    HydropowerPlantsPatchResponse,
    HydropowerPlantsPatchResponseFromJSON,
    HydropowerPlantsPatchResponseToJSON,
} from '../models';

export interface V1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGetRequest {
    sensor: string;
    hydropowerPlantId: string;
    start?: number;
    end?: number;
    groupBy?: string;
}

export interface V1HydropowerPlantHydropowerPlantIdStateElapsedGetRequest {
    hydropowerPlantId: string;
    stateStart?: string;
    stateStop?: string;
    activeStart?: string;
    activeStop?: string;
    start?: number;
    end?: number;
    groupBy?: string;
}

export interface V1HydropowerPlantPatchRequest {
    hydropowerPlant?: HydropowerPlant;
}

export interface V1HydropowerPlantSensorSensorDataGetRequest {
    sensor: string;
    start?: number;
    end?: number;
    groupBy?: string;
}

export interface V1HydropowerPlantSensorSensorDifferenceGetRequest {
    sensor: string;
    start?: number;
    end?: number;
    groupBy?: string;
}

/**
 * no description
 */
export class HydropowerPlantApi extends runtime.BaseAPI {

    /**
     */
    async v1HydropowerPlantGetRaw(): Promise<runtime.ApiResponse<HydropowerPlantsGetResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/HydropowerPlant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HydropowerPlantsGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1HydropowerPlantGet(): Promise<HydropowerPlantsGetResponse> {
        const response = await this.v1HydropowerPlantGetRaw();
        return await response.value();
    }

    /**
     */
    async v1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGetRaw(requestParameters: V1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGetRequest): Promise<runtime.ApiResponse<HydropowerPlantSensorPredictionGetResponse>> {
        if (requestParameters.sensor === null || requestParameters.sensor === undefined) {
            throw new runtime.RequiredError('sensor','Required parameter requestParameters.sensor was null or undefined when calling v1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGet.');
        }

        if (requestParameters.hydropowerPlantId === null || requestParameters.hydropowerPlantId === undefined) {
            throw new runtime.RequiredError('hydropowerPlantId','Required parameter requestParameters.hydropowerPlantId was null or undefined when calling v1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/HydropowerPlant/{hydropowerPlantId}/sensor/{sensor}/prediction`.replace(`{${"sensor"}}`, encodeURIComponent(String(requestParameters.sensor))).replace(`{${"hydropowerPlantId"}}`, encodeURIComponent(String(requestParameters.hydropowerPlantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HydropowerPlantSensorPredictionGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGet(requestParameters: V1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGetRequest): Promise<HydropowerPlantSensorPredictionGetResponse> {
        const response = await this.v1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async v1HydropowerPlantHydropowerPlantIdStateElapsedGetRaw(requestParameters: V1HydropowerPlantHydropowerPlantIdStateElapsedGetRequest): Promise<runtime.ApiResponse<HydropowerPlantStateElapsedGetResponse>> {
        if (requestParameters.hydropowerPlantId === null || requestParameters.hydropowerPlantId === undefined) {
            throw new runtime.RequiredError('hydropowerPlantId','Required parameter requestParameters.hydropowerPlantId was null or undefined when calling v1HydropowerPlantHydropowerPlantIdStateElapsedGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.stateStart !== undefined) {
            queryParameters['stateStart'] = requestParameters.stateStart;
        }

        if (requestParameters.stateStop !== undefined) {
            queryParameters['stateStop'] = requestParameters.stateStop;
        }

        if (requestParameters.activeStart !== undefined) {
            queryParameters['activeStart'] = requestParameters.activeStart;
        }

        if (requestParameters.activeStop !== undefined) {
            queryParameters['activeStop'] = requestParameters.activeStop;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/HydropowerPlant/{hydropowerPlantId}/state/elapsed`.replace(`{${"hydropowerPlantId"}}`, encodeURIComponent(String(requestParameters.hydropowerPlantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HydropowerPlantStateElapsedGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1HydropowerPlantHydropowerPlantIdStateElapsedGet(requestParameters: V1HydropowerPlantHydropowerPlantIdStateElapsedGetRequest): Promise<HydropowerPlantStateElapsedGetResponse> {
        const response = await this.v1HydropowerPlantHydropowerPlantIdStateElapsedGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async v1HydropowerPlantPatchRaw(requestParameters: V1HydropowerPlantPatchRequest): Promise<runtime.ApiResponse<HydropowerPlantsPatchResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/HydropowerPlant`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HydropowerPlantToJSON(requestParameters.hydropowerPlant),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HydropowerPlantsPatchResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1HydropowerPlantPatch(requestParameters: V1HydropowerPlantPatchRequest): Promise<HydropowerPlantsPatchResponse> {
        const response = await this.v1HydropowerPlantPatchRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async v1HydropowerPlantSensorSensorDataGetRaw(requestParameters: V1HydropowerPlantSensorSensorDataGetRequest): Promise<runtime.ApiResponse<HydropowerPlantSensorDataGetResponse>> {
        if (requestParameters.sensor === null || requestParameters.sensor === undefined) {
            throw new runtime.RequiredError('sensor','Required parameter requestParameters.sensor was null or undefined when calling v1HydropowerPlantSensorSensorDataGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/HydropowerPlant/sensor/{sensor}/data`.replace(`{${"sensor"}}`, encodeURIComponent(String(requestParameters.sensor))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HydropowerPlantSensorDataGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1HydropowerPlantSensorSensorDataGet(requestParameters: V1HydropowerPlantSensorSensorDataGetRequest): Promise<HydropowerPlantSensorDataGetResponse> {
        const response = await this.v1HydropowerPlantSensorSensorDataGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async v1HydropowerPlantSensorSensorDifferenceGetRaw(requestParameters: V1HydropowerPlantSensorSensorDifferenceGetRequest): Promise<runtime.ApiResponse<HydropowerPlantSensorDifferenceGetResponse>> {
        if (requestParameters.sensor === null || requestParameters.sensor === undefined) {
            throw new runtime.RequiredError('sensor','Required parameter requestParameters.sensor was null or undefined when calling v1HydropowerPlantSensorSensorDifferenceGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.groupBy !== undefined) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/HydropowerPlant/sensor/{sensor}/difference`.replace(`{${"sensor"}}`, encodeURIComponent(String(requestParameters.sensor))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HydropowerPlantSensorDifferenceGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async v1HydropowerPlantSensorSensorDifferenceGet(requestParameters: V1HydropowerPlantSensorSensorDifferenceGetRequest): Promise<HydropowerPlantSensorDifferenceGetResponse> {
        const response = await this.v1HydropowerPlantSensorSensorDifferenceGetRaw(requestParameters);
        return await response.value();
    }

}
