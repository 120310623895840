/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginPostResponse
 */
export interface LoginPostResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginPostResponse
     */
    authId?: string;
}

export function LoginPostResponseFromJSON(json: any): LoginPostResponse {
    return LoginPostResponseFromJSONTyped(json, false);
}

export function LoginPostResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginPostResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authId': !exists(json, 'authId') ? undefined : json['authId'],
    };
}

export function LoginPostResponseToJSON(value?: LoginPostResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authId': value.authId,
    };
}


