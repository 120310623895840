/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StringStringKeyValuePair
 */
export interface StringStringKeyValuePair {
    /**
     * 
     * @type {string}
     * @memberof StringStringKeyValuePair
     */
    readonly key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringStringKeyValuePair
     */
    readonly value?: string | null;
}

export function StringStringKeyValuePairFromJSON(json: any): StringStringKeyValuePair {
    return StringStringKeyValuePairFromJSONTyped(json, false);
}

export function StringStringKeyValuePairFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringStringKeyValuePair {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function StringStringKeyValuePairToJSON(value?: StringStringKeyValuePair | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


