import { navigate } from "@reach/router";
import { Reducer } from "react";
import { AsyncActionHandlers } from 'use-reducer-async';

import jwt_decode from 'jwt-decode';
import { Action, ActionTypes, AsyncAction, IPersistedState, IState } from ".";
import { Configuration, ConfigurationParameters, CustomerApi, HydropowerPlantApi, LoginApi, HydropowerPlantStateElapsedGetResponse } from "../service";

function getToken(): string {
    try {
        const _storage = window.localStorage.getItem('storage');
        const storage: IPersistedState = (JSON.parse(_storage));
        return storage.token;
    } catch (err) {
        // dispatch({ type: ActionTypes.LOOKUP_BY_METER_NUMBER_SUCCESS, request: lookupRequest, response: result, id: uuid });
        return "";
    }
}

export function getConfig(includeToken: boolean = true): ConfigurationParameters {
    if (includeToken) {
        return {
            apiKey: "Bearer " + getToken(),
            basePath: process.env.GATSBY_KINETIKAPI_URL,
        };
    } else {
        return {
            basePath: process.env.GATSBY_KINETIKAPI_URL,
        };
    }
}

export const asyncActionHandlers: AsyncActionHandlers<Reducer<IState, Action>, AsyncAction> = {
    LOGIN_START: ({ dispatch }) => async ({ request }) => {
        dispatch({ type: ActionTypes.LOGIN_START_STARTED });
        try {
            const loginapi: LoginApi = new LoginApi(new Configuration(getConfig(false)));
            const result = await loginapi.v1LoginStartPost(request);
            dispatch({ type: ActionTypes.LOGIN_START_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.LOGIN_START_FAILED, message: error });
            throw error;
        }
    },
    LOGIN_VERIFY: ({ dispatch }) => async ({ request }) => {
        dispatch({ type: ActionTypes.LOGIN_VERIFY_STARTED });
        try {
            const loginapi: LoginApi = new LoginApi(new Configuration(getConfig(false)));
            const result = await loginapi.v1LoginVerifyPost(request);
            dispatch({ type: ActionTypes.LOGIN_VERIFY_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.LOGIN_VERIFY_FAILED, message: error });
            throw error;
        }
    },
    GET_HYDROPOWERPLANTS: ({ dispatch }) => async () => {
        dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTS_START });
        try {
            const config = getConfig(true);
            const hydropowerapi: HydropowerPlantApi = new HydropowerPlantApi(new Configuration(config));
            const result = await hydropowerapi.v1HydropowerPlantGet();
            dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTS_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTS_FAILED, message: error });
            throw error;
        }
    },
    GET_CUSTOMER: ({ dispatch }) => async () => {
        dispatch({ type: ActionTypes.GET_CUSTOMER_START });
        try {
            const customerapi: CustomerApi = new CustomerApi(new Configuration(getConfig(true)));
            const result = await customerapi.v1CustomerGet();
            dispatch({ type: ActionTypes.GET_CUSTOMER_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.GET_CUSTOMER_FAILED, message: error });
            throw error;
        }
    },
    GET_CUSTOMERDATA: ({ dispatch }) => async ({ request }) => {
        dispatch({ type: ActionTypes.GET_CUSTOMERDATA_START });
        try {
            const customerapi: CustomerApi = new CustomerApi(new Configuration(getConfig(true)));
            const result = await customerapi.v1CustomerDataGet(request);
            dispatch({ type: ActionTypes.GET_CUSTOMERDATA_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.GET_CUSTOMERDATA_FAILED, message: error });
            throw error;
        }
    },
    GET_CUSTOMEREVENTS: ({ dispatch }) => async ({ request }) => {
        dispatch({ type: ActionTypes.GET_CUSTOMEREVENTS_START });
        try {
            const customerapi: CustomerApi = new CustomerApi(new Configuration(getConfig(true)));
            const result = await customerapi.v1CustomerEventsGet(request);
            dispatch({ type: ActionTypes.GET_CUSTOMEREVENTS_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.GET_CUSTOMEREVENTS_FAILED, message: error });
            throw error;
        }
    },
    GET_CUSTOMEREVENTSCOUNT: ({ dispatch }) => async ({ request }) => {
        dispatch({ type: ActionTypes.GET_CUSTOMEREVENTSCOUNT_START });
        try {
            const customerapi: CustomerApi = new CustomerApi(new Configuration(getConfig(true)));
            const result = await customerapi.v1CustomerEventsCountGet(request);
            dispatch({ type: ActionTypes.GET_CUSTOMEREVENTSCOUNT_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.GET_CUSTOMEREVENTSCOUNT_FAILED, message: error });
            throw error;
        }
    },
    GET_CUSTOMEREVENTSCOUNTDAY: ({ dispatch }) => async ({ request }) => {
        dispatch({ type: ActionTypes.GET_CUSTOMEREVENTSCOUNTDAY_START });
        try {
            const customerapi: CustomerApi = new CustomerApi(new Configuration(getConfig(true)));
            const result = await customerapi.v1CustomerEventsCountGet(request);
            dispatch({ type: ActionTypes.GET_CUSTOMEREVENTSCOUNTDAY_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.GET_CUSTOMEREVENTSCOUNTDAY_FAILED, message: error });
            throw error;
        }
    },
    GET_HYDROPOWERPLANTSENSORDATA: ({ dispatch }) => async ({ request }) => {
        dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSENSORDATA_START });
        try {
            const hydropowerplantapi: HydropowerPlantApi = new HydropowerPlantApi(new Configuration(getConfig(true)));
            const result = await hydropowerplantapi.v1HydropowerPlantSensorSensorDataGet(request);
            dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSENSORDATA_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSENSORDATA_FAILED, message: error });
            throw error;
        }
    },
    GET_HYDROPOWERPLANTSENSORDIFFERENCE: ({ dispatch }) => async ({ request }) => {
        dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSENSORDIFFERENCE_START });
        try {
            const hydropowerplantapi: HydropowerPlantApi = new HydropowerPlantApi(new Configuration(getConfig(true)));
            const result = await hydropowerplantapi.v1HydropowerPlantSensorSensorDifferenceGet(request);
            dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSENSORDIFFERENCE_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSENSORDIFFERENCE_FAILED, message: error });
            throw error;
        }
    },
    GET_HYDROPOWERPLANTSTATEELAPSED: ({ dispatch }) => async ({ request }) => {
        dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSTATEELAPSED_START });
        try {
            const hydropowerplantapi: HydropowerPlantApi = new HydropowerPlantApi(new Configuration(getConfig(true)));
            const result = await hydropowerplantapi.v1HydropowerPlantHydropowerPlantIdStateElapsedGet(request);
            dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSTATEELAPSED_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSTATEELAPSED_FAILED, message: error });
            throw error;
        }
    },
    GET_HYDROPOWERPLANTSENSORPREDICTION: ({ dispatch }) => async ({ request }) => {
        dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSENSORPREDICTION_START });
        try {
            const hydropowerplantapi: HydropowerPlantApi = new HydropowerPlantApi(new Configuration(getConfig(true)));
            const result = await hydropowerplantapi.v1HydropowerPlantHydropowerPlantIdSensorSensorPredictionGet(request);
            dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSENSORPREDICTION_SUCCESS, data: result });
            return;
        } catch (error) {
            dispatch({ type: ActionTypes.GET_HYDROPOWERPLANTSENSORPREDICTION_FAILED, message: error });
            throw error;
        }
    }
};

export function getTokenClaims() {
    try {
        const token = getToken();
        const decoded = jwt_decode(token);
        const isTokenValid = new Date(decoded.exp * 1000) > new Date();
        if (isTokenValid) {
            const _name = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
            const names = _name.split(' ');
            let _initials = names[0].substring(0, 1).toUpperCase();

            if (names.length > 1) {
                _initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
        } else {
            // dispatch({ type: ActionTypes.LOOKUP_BY_METER_NUMBER_FAILED, request: lookupRequest, message: error, id: uuid });
        }
    } catch (error) {
        // Failed to decode token. It must be invalid then
    }
}

export function validateToken(token: string, pathname: string) {
    try {
        const decoded = jwt_decode(token);
        const isTokenValid = new Date(decoded.exp * 1000) > new Date();
        if (isTokenValid) {
            getTokenClaims();
            if (pathname === '/') {
                navigate('/dashboard');
            }
            //dispatch({ type: ActionTypes.TOKEN_VALID });
        } else {
            if (pathname !== '/') {
                navigate('/');
            }
        }
    } catch (error) {
        // Failed to decode token. It must be invalid then.
        if (pathname !== '/') {
            navigate('/');
        } else {
            // Nope
        }
    }
}
