/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Admin,
    AdminFromJSON,
    AdminFromJSONTyped,
    AdminToJSON,
    HydropowerData,
    HydropowerDataFromJSON,
    HydropowerDataFromJSONTyped,
    HydropowerDataToJSON,
    HydropowerPlant,
    HydropowerPlantFromJSON,
    HydropowerPlantFromJSONTyped,
    HydropowerPlantToJSON,
    StringStringKeyValuePair,
    StringStringKeyValuePairFromJSON,
    StringStringKeyValuePairFromJSONTyped,
    StringStringKeyValuePairToJSON,
} from './';

/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    description?: string | null;
    /**
     * 
     * @type {Array<HydropowerPlant>}
     * @memberof Customer
     */
    hydropowerPlants?: Array<HydropowerPlant> | null;
    /**
     * 
     * @type {HydropowerData}
     * @memberof Customer
     */
    hydropowerData?: HydropowerData | null;
    /**
     * 
     * @type {Array<Admin>}
     * @memberof Customer
     */
    admins?: Array<Admin> | null;
    /**
     * 
     * @type {object}
     * @memberof Customer
     */
    properties?: object | null;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    updated?: Date;
    /**
     * 
     * @type {Array<StringStringKeyValuePair>}
     * @memberof Customer
     */
    values?: Array<StringStringKeyValuePair> | null;
}

export function CustomerFromJSON(json: any): Customer {
    return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Customer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'hydropowerPlants': !exists(json, 'hydropowerPlants') ? undefined : (json['hydropowerPlants'] === null ? null : (json['hydropowerPlants'] as Array<any>).map(HydropowerPlantFromJSON)),
        'hydropowerData': !exists(json, 'hydropowerData') ? undefined : HydropowerDataFromJSON(json['hydropowerData']),
        'admins': !exists(json, 'admins') ? undefined : (json['admins'] === null ? null : (json['admins'] as Array<any>).map(AdminFromJSON)),
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'values': !exists(json, 'values') ? undefined : (json['values'] === null ? null : (json['values'] as Array<any>).map(StringStringKeyValuePairFromJSON)),
    };
}

export function CustomerToJSON(value?: Customer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'hydropowerPlants': value.hydropowerPlants === undefined ? undefined : (value.hydropowerPlants === null ? null : (value.hydropowerPlants as Array<any>).map(HydropowerPlantToJSON)),
        'hydropowerData': HydropowerDataToJSON(value.hydropowerData),
        'admins': value.admins === undefined ? undefined : (value.admins === null ? null : (value.admins as Array<any>).map(AdminToJSON)),
        'properties': value.properties,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'values': value.values === undefined ? undefined : (value.values === null ? null : (value.values as Array<any>).map(StringStringKeyValuePairToJSON)),
    };
}


