/* tslint:disable */
/* eslint-disable */
/**
 * kinetikapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KinetikEventCount,
    KinetikEventCountFromJSON,
    KinetikEventCountFromJSONTyped,
    KinetikEventCountToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerEventsCountGetResponse
 */
export interface CustomerEventsCountGetResponse {
    /**
     * 
     * @type {Array<KinetikEventCount>}
     * @memberof CustomerEventsCountGetResponse
     */
    kinetikEventsCount?: Array<KinetikEventCount> | null;
}

export function CustomerEventsCountGetResponseFromJSON(json: any): CustomerEventsCountGetResponse {
    return CustomerEventsCountGetResponseFromJSONTyped(json, false);
}

export function CustomerEventsCountGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerEventsCountGetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kinetikEventsCount': !exists(json, 'kinetikEventsCount') ? undefined : (json['kinetikEventsCount'] === null ? null : (json['kinetikEventsCount'] as Array<any>).map(KinetikEventCountFromJSON)),
    };
}

export function CustomerEventsCountGetResponseToJSON(value?: CustomerEventsCountGetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kinetikEventsCount': value.kinetikEventsCount === undefined ? undefined : (value.kinetikEventsCount === null ? null : (value.kinetikEventsCount as Array<any>).map(KinetikEventCountToJSON)),
    };
}


